<template>
    <QuestionPopup :answer_btn="data?.answer_btn" :data = "data_question"/>
    <main>
        <section id="detail-product">
            <!-- <pre>{{ data }}</pre> -->
            <div class="container">
                <a href="/#product-slider" class="back-btn">
                    <svg CLA width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.792892 8.20711C0.402369 7.81658 0.402369 7.18342 0.792892 6.79289L7.15685 0.428932C7.54738 0.0384078 8.18054 0.0384078 8.57107 0.428932C8.96159 0.819457 8.96159 1.45262 8.57107 1.84315L2.91421 7.5L8.57107 13.1569C8.96159 13.5474 8.96159 14.1805 8.57107 14.5711C8.18054 14.9616 7.54738 14.9616 7.15685 14.5711L0.792892 8.20711ZM25.5 8.5H1.5V6.5H25.5V8.5Z" fill="#363636"/>
                    </svg>
                    {{ data?.product.back }}
                </a>
                <div class="product-text-slider">
                    <div class="slider-product">
                        <div class="main-product-img">
                            <img ref="main_img" :src="data?.imgs_slider[current_img]" alt="">
                        </div>
                        
                    </div>
                    <div class="product-text-container">
                        <div class="product-warn" v-html="data?.product.not_med"></div>
                        <h1 v-html="data?.title"></h1>
                        <div class="product-text" v-html="data?.text"></div>
                        <div class="product-cena-cod">
                            <div class="product-cena-cod-price">
                                <div class="product-cena">
                                    <span class="product-cena-title" v-html="data?.product.price"></span>
                                    <div>
                                        <span class="product-cena-price-partner" v-html="data?.price_partner"></span>
                                        <span class="product-cena-price" v-html="data?.price"></span>
                                    </div>
                                </div>
                                <div class="product-size">
                                    <span v-html="data?.product.volume"></span>
                                    <span v-html="data?.size"></span>
                                </div>
                                <div class="product-cod">
                                    <span>CV</span>
                                    <span v-html="data?.code"></span>
                                </div>
                            </div>
                            <div class="product-cena-cod-btn" @click="popupOpen" v-html="data?.banner.btn_text"></div>
                            <!-- <pre>{{data}}</pre> -->
                        </div>
                        <div class="pagination-product-imgs">
                            <div class="product-img" :class="no_opacity ? 'no-click' : '', index == current_img ? 'active' : ''" @click="setCurrentImg(index), no_click = false" v-for="item, index in data?.imgs_slider">
                                <img :src="item" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="products-stats">
                    <div class="stats-swiper-shadow" :class="slider_end ? 'end': ''">
                        <div ref="stats_swiper" class="swiper stats-swiper mobile">
                            <div class="swiper-wrapper">
                                <div v-for="item, index in data?.description" @click="setCurrentDesc(index)" :class="index == current_desc? 'cur' : ''" class="swiper-slide" v-html="item?.text_btn"></div>
                            </div>
                        </div>
                    </div>
                    <div class="stats desktop">
                        <div v-for="item, index in data?.description" @click="setCurrentDesc(index)" :class="index == current_desc? 'cur' : ''" class="stat" v-html="item?.text_btn"></div>
                    </div>
                    <div ref="slider_content" class="swiper stats-swiper-content">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item, index in data?.description">
                                <div ref="stats_content" class="products-stats-content">
                                    <h3>{{data?.description[index].text_btn}}</h3>
                                    <div class="stats-content-left" v-html="item.text_description_left"></div>
                                    <div class="stats-content-right" v-html="item.text_description_right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="recommends-container" v-if="data?.recommend">
                    <h2 v-html="data?.recommend_title"></h2>
                    <div ref="slider_recomends" class="swiper recommends-swiper-content desktop">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item, index in data?.recommend">
                                <a class="product-item" :href="item?.id">
                                    <div class="product-item-img">
                                        <img :src="item?.img" alt="">
                                    </div>
                                    <p v-html="item?.title"></p>
                                    <div class="product-item-prices">
                                        <div class="product-item-prices-rub">
                                            <span class="product-item-price-partner" v-html="item?.price_partner"></span>
                                            <span class="product-item-price" v-html="item?.price"></span>
                                        </div>
                                        <span class="product-item-cv" v-html="item?.code"></span>
                                    </div>
                                    <button class="product-item-btn"><span>{{ data?.product.product_watch }}</span></button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="recommends-list mobile">
                        <a class="recommends-item" :href="item?.id" v-for="item, index in data?.recommend">
                            <img :src="item?.img" alt="">
                            <p v-html="item?.title"></p>
                            <span v-html="item?.price"></span>
                        </a>
                    </div>
                    <a :href="data?.more_btn.link" class="recommends-btn-more mobile" v-html="data?.more_btn.text"></a>
                </div>
                <div class="ceo" v-if="data?.seo_text">
                    {{ data?.seo_text }}
                    <div class="ceo-btns">
                        <button class="ceo-btns-reg" @click="openRedirect(data?.login_link)">{{ data?.product.register }}</button>
                        <button class="ceo-btns-get" @click="popupOpen">{{ data?.product.consultation }}</button>
                    </div>
                </div>
                <!-- <template v-else>
                    <div class="ceo-no-text">
                        <div class="ceo-btns">
                            <button class="ceo-btns-get" @click="popupOpen">{{ data?.product.consultation }}</button>
                        </div>
                    </div>
                </template> -->
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";
import router from "@/router";
import Swiper from 'swiper';
import QuestionPopup from '@/components/UI/QuestionPopup.vue'
import { mapMutations, mapState } from "vuex";

export default {
    components: {
        QuestionPopup
    },
    data() {
        return {
            data: null,
            current_img: 0,
            id: 0,
            no_click: true,
            current_desc: 0,
            slider: null,
            slider_end: false,
            slider_content: null,
            slider_recomends: null,
            data_question: null,
            no_opacity: false
        }
    },
    methods: {
        ...mapMutations({
            setLoading: "page/setLoading",
        }),

        async getData() {
            try {
                const result = await axios.get(
                "https://mayaydyryssova.com/v1/methods/product/" + router.currentRoute.value.params.id + '?' + new URLSearchParams({ lang: this.currentLang }).toString()
                );
                this.data = result.data;
                setTimeout(() => {
                    this.setLoading(false);
                }, 2000);
            } catch (error) {
                router.push("/404");
                console.clear();
                console.error(error);
            }
        },
        openQuestionPopup(data) {
            this.data_question = data;
            this.$store.commit("setQuestionPopup", true);
            document.querySelector('body').style.overflow = "hidden";
        },
        setCurrentImg(id) {
            if (id != this.current_img && this.no_opacity != true) {
                this.no_opacity = true
                const imgs = document.querySelectorAll('.product-img')
                this.$refs.main_img.style.opacity = 0

                    this.current_img = id
                    this.$refs.main_img.style.opacity = 1
                    this.no_opacity = false

            }
        },
        setCurrentDesc(id) {
            this.current_desc = id
            this.slider.slideTo(id);
            this.slider_content.slideTo(id);
        },
        popupOpen() {
            this.$store.commit('currentPlace', 'Банер -20% на странице продукта ' + this.data?.title);
            this.$store.commit("openPopup", true);
        },
        openRedirect(link) {
            this.$store.commit("openRedirect", true);
            this.$store.commit("setPopupLink", link);
        },
    },
    computed: {
        ...mapState({
            currentLang: state => state.currentLang,
        })
    },
    watch: {
        currentLang() {
        setTimeout(() => {
            this.getData();
        }, 500);
        },
    },
    mounted() {
        this.getData();
        const interval = setInterval(() => {
            if (this.data != null) {
                this.slider = new Swiper(this.$refs.stats_swiper, {
                    spaceBetween: 2,
                    slidesPerView: 'auto'
                });
                this.slider.on('reachEnd', () => {
                    this.slider_end = true;
                });
                this.slider.on('reachBeginning', () => {
                    this.slider_end = false;
                });
                this.slider_content = new Swiper(this.$refs.slider_content, {
                    touchRatio: 0,
                    slidesPerView: 1,
                    watchSlidesProgress: true
                });
                if (this.data?.recommend.length < 5 && window.clientWidth > 1200) {
                } else {
                    this.slider_recomends = new Swiper(this.$refs.slider_recomends, {
                        slidesPerView: 'auto',
                        spaceBetween: 8,
                    });
                }
                clearInterval(interval);
            }
        }, 50);
        const interval1 = setInterval(() => {
            if (this.data?.imgs_slider.length > 1) {     
                if (this.no_click != true) {
                    clearInterval(interval1);
                    return;
                }
                if (this.current_img == this.data?.imgs_slider.length - 1) {
                    this.id = 0
                    this.setCurrentImg(this.id);
                } else {
                    this.id += 1
                    this.setCurrentImg(this.id);
                }
            }
        }, 3000);
    },
}
</script>
<style>
    .ceo {
        width: 100%;
        resize: none; 
        height: 360px;
        border: 1px solid var(--re-light-gray);
        border-radius: 32px;
        padding: 34px 49px;
        color: var(--gray);
        position: relative;
        background-color: var(--light_gray);
        
    }
    .ceo-btns{
        position: absolute;
        bottom: 25px;
        right: 24px;
        display: flex;
        gap: 8px;
    }
    .ceo-btns button{
        padding: 17px 24px;
        border-radius:  16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
    }
    .ceo-btns-reg {
        background-color: var(--re-light-blue);
        color: var(--blue);
    }
    .ceo-btns-get {
        background-color: var(--blue);
        color: var(--white);
        transition: .5s;
    }
    .ceo-btns-get:hover{
        background-color: var(--light_blue);
    }
    #detail-product {
        margin-top: 80px;
        padding-top: 32px;
        margin-bottom: 128px;
    }

    .product-item-btn {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        background-color: var(--white);
        border-radius: 16px;
        font-weight: 700;
        border: 0;
        position: relative;
    }

    .recommends-swiper-content .swiper-slide .product-item-btn span{
        position: relative;
        z-index: 15;
        color: var(--re-black);
        font-size: 13px;
        transition: .5s;
    }

    .product-item-btn::after {
        content: "";
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top:0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        background: linear-gradient(to left, #018d96 0%, #01adb7 100%);
        transition: .5s;
        z-index: 10;
    }

    .recommends-swiper-content .swiper-slide:hover .product-item-btn::after {
        opacity: 1;
    }

    .recommends-swiper-content .swiper-slide:hover .product-item-btn span {
        color: var(--white);
    }

    .recommends-swiper-content .swiper-slide span.product-item-price-partner{
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
    }

    .recommends-swiper-content .swiper-slide span.product-item-price{
        font-size: 13px;
        font-weight: 600;
        line-height: 14px;
        margin-left: 6px;
        text-decoration: line-through;
        color: var(--re-light-gray-text)
    }

    .recommends-swiper-content .swiper-slide span.product-item-cv{
        font-size: 13px;
        font-weight: 600;
        line-height: 14px;
        color: var(--re-light-gray-text);
        height: 16px;
    }
    .product-item-prices{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .product-item{
        background-color: var(--re-light-green);
        border-radius: 24px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .product-item-img{
        padding: 36px;
        width: 100%;
        height: 205px;
        border-radius: 16px;
        background-color: var(--white);
    }
    .product-item-img p{
       font-size: 15px;
       font-weight: 600;
       line-height: 20px
    }

    .back-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        color: var(--re-black);
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        width: max-content;
        position: relative;
    }

    .back-btn svg {
        height: 45px;
        width: 45px;
        padding: 0 10px;
        border-radius: 50%;
        background-color: var(--sky);
        position: relative;
    }

    .back-btn::after{
        background: linear-gradient(to left, #0a97a0d6 0%, #0a97a0d6 100%);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 50%;
        aspect-ratio: 1/1;
        opacity: 0;
        z-index: 1;
        transition: .5s;
    }

    /* .back-btn:hover::after{
        opacity: 1;
    } */

    .product-text-slider {
        display: grid;
        grid-template-columns: 683px 1fr;
        gap: 41px;
        margin-top: 6px;
    }

    .product-warn{
        color: var(--gray);
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 7px;
    }

    .product-text-container h1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        margin-bottom: 8px;
    }

    .product-text p{
        font-size: 13px;
        font-weight: 400;
        line-height: 156%;
        margin-bottom: 35px;
    }

    .product-cena-cod {
        width: 100%;
        display: grid;
        grid-template-rows: 2fr;
        gap: 17px;
        padding: 20px 54px 24px 21px;
        border: 1px solid #00000029;
        border-radius: 32px;
        margin-bottom: 36px;
    }

    .product-cena-cod-price{
        display: flex;
        justify-content: space-between;
    }

    .product-cena,
    .product-cod,
    .product-size {
        display: flex;
        flex-direction: column;
        width: max-content;
        gap: 0px;
    }

    .product-cena span:first-of-type,
    .product-cod span:first-of-type,
    .product-size span:first-of-type {
        color: rgb(150, 150, 150);
        font-size: 12px;
        font-weight: 700;
        line-height: 164%;
    }

    .product-cena span:last-of-type,
    .product-cod span:last-of-type,
    .product-size span:last-of-type {
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
        color: var(--re-black);
    }

    .product-cena span.product-cena-title{
        font-size: 12px; 
        line-height: 164%;
    }

    .product-cena span.product-cena-price-partner{
        color: var(--re-black);
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
    }

    .product-cena span.product-cena-price:after {
        content:"";
        display:block;
        position:absolute;
        left:0;
        top:30%;
        color:var(--re-black);
        width:100%;
        height:0;
        border-bottom:1px solid var(--re-black);
        transform:rotate(-10deg);
    }

    .ceo-no-text{
        position: relative;
        display: flex;
        justify-content: flex-end;
    }

    .ceo-no-text .ceo-btns{
        position: relative;
        bottom: 0;
        right: 0;
    }

    .product-cena span.product-cena-price{
        color: var(--re-very-light-gray);
        font-size: 12px;
        font-weight: 700;
        line-height: 164%;
        margin-left: 7px;
        position: relative;
    }


    .main-product-img {
        width: 100%;
        height: 483px;
        /* background-color: #08747c; */
        border-radius: 48px;
    }

    .main-product-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 48px;
        transition: 0.5s;
    }

    .pagination-product-imgs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
    }

    .product-img.no-click {
        pointer-events: none;
    }

    .pagination-product-imgs .product-img {
        width: 100%;
        height: 84px;
        cursor: pointer;
        border-radius: 24px;
        /* background-color: #08747c; */
        transition: .5s;
        border: 2px solid #00000000;
    }

    .pagination-product-imgs .product-img.active {
        border: 2px solid #0A97A0;
    }

    .pagination-product-imgs .product-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 24px;
    }

    .banner-20 {
        border-radius: 16px;
        background: var(--sky);
        padding-top: 17px;
        padding-left: 21px;
        padding-right: 19px;
        padding-bottom: 20px;
        width: max-content;
        margin-bottom: 32px;
    }

    .banner-text {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .banner-text span {
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
    }

    .banner-text p {
        font-size: 14px;
        font-weight: 700;
        line-height: 136%;
    }

    .product-cena-cod-btn {
        font-size: 14px;
        font-weight: 700;
        line-height: 132%;
        border-radius: 12px;
        width: max-content;
        padding: 16px 24px;
        cursor: pointer;
        transition: 0.5s;
        color: var(--white);
        background: var(--blue);
    }

    .product-cena-cod-btn:hover {
        background: var(--light_blue);
    }

    .product-question {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .product-question-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 156%;
    }

    .product-question-title-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .product-question-item {
        color: var(--blue);
        display: block;
        font-size: 13px;
        font-weight: 600;
        line-height: 136%;
        cursor: pointer;
        transition: 0.5s;
    }

    .product-question-item:hover {
        color: #08747c;
    }

    .stats-swiper .swiper-slide,
    .stat {
        width: max-content;
        font-size: 15px;
        font-weight: 700;
        line-height: 108%;
        padding: 16px;
        border-radius: 12px;
        background: var(--white);
        border: 1px solid var(--white);
        cursor: pointer;
        transition: 0.5s;
        color: var(--re-black);
    }

    .stat:hover {
        background-color: var(--light_gray);
    }

    .stats-swiper .swiper-slide:hover {
        color: var(--blue);
    }

    .stats-swiper,
    .stats {
        border-radius: 14px;
        /* background: rgba(54, 54, 54, 0.04);; */
        /* padding: 2px; */
        width: 100%;
    }

    .stats {
        display: flex;
        flex-direction: row;
        width: max-content;
        gap: 2px;
    }

    .stats-swiper-shadow {
        position: relative;
        width: 100%;
    }

    .stats-swiper-shadow::after,
    .stats-swiper-shadow::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        z-index: 100;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 14px;
        pointer-events: none;
        transition: 0.5s;
    }

    .stats-swiper-shadow::after {
        box-shadow: inset -48px 0px 12px 0px rgba(0, 0, 0, 0.12);
    }

    .stats-swiper-shadow.end::after {
        opacity: 0;
    }

    .stats-swiper-shadow::before {
        opacity: 0;
        box-shadow: inset 48px 0px 12px 0px rgba(0, 0, 0, 0.12);
    }

    .stats-swiper-shadow.end::before {
        opacity: 1;
    }

    .products-stats {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 37px;
    }

    .stats-swiper-content {
        width: 100%;
    }

    .stats-content-left p,
    .stats-content-right p {
        font-size: 13px;
        font-weight: 600;
        line-height: 156%;
    }

    .stats-content-left h3,
    .stats-content-right h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
    }


    .stats-swiper-content .swiper-slide {
        opacity: 0;
    }
    .stats-swiper-content .swiper-slide-visible {
        opacity: 1;
    }

    .products-stats-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 13px;
        font-weight: 600;
        line-height: 156%;
    }

    .products-stats-content h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
        margin-bottom: 12px;
    }

    .recommends-container {
        margin-top: 177px;
        margin-bottom: 157px;
    }

    .recommends-container h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 124%;
        margin-bottom: 33px;
    }

    .recommends-swiper-content {
        width: 100%;
    }

    .recommends-swiper-content .swiper-slide {
        width: 294px;
    }

    .recommends-swiper-content .swiper-slide p,
    .recommends-swiper-content .swiper-slide span,
    .recommends-item p,
    .recommends-item span {
        transition: 0.5s;
    }

    .recommends-swiper-content .swiper-slide:hover p,
    .recommends-item:hover p,
    .recommends-item:hover span {
        color: var(--blue);
    }

    /*  */

    .recommends-swiper-content .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 4px;
    }

    .recommends-swiper-content .swiper-slide p {
        font-size: 15px;
        font-weight: 700;
        line-height: 136%;
    }

    .recommends-swiper-content .swiper-slide span {
        font-size: 16px;
        font-weight: 700;
        line-height: 136%;
        color: var(--gray);
    }

    .stats-swiper .swiper-slide.cur,
    .stat.cur {
        color: var(--blue);
        border-color: var(--blue);
        background: #0A97A014;
    }

    @media screen and (max-width: 1200px) {
        .recommends-container h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 124%;
        }

        .recommends-container {
            margin-top: 96px;
        }

        .product-text-slider {
            grid-template-columns: 1fr;
            gap: 24px;
        }

        .main-product-img {
            margin-bottom: 8px;
        }

        .pagination-product-imgs .product-img {
            height: 58px;
        }

        .pagination-product-imgs {
            gap: 8px;
        }

        .banner-20 {
            display: none;
        }

        .product-text-container h1 {
            font-size: 28px;
            font-weight: 700;
            line-height: 124%;
        }

        .product-text {
            font-size: 13px;
            font-weight: 400;
            line-height: 156%;
        }

        .product-cena span:first-of-type, 
        .product-cod span:first-of-type,
        .product-size span:first-of-type {
            font-size: 12px;
            font-weight: 700;
            line-height: 164%;
        }

        .product-cena span:last-of-type,
        .product-cod span:last-of-type,
        .product-size span:last-of-type {
            font-size: 18px;
            font-weight: 700;
            line-height: 124%;
        }

        .product-question-title {
            font-size: 14px;
            font-weight: 700;
            line-height: 156%;
        }

        .product-question-item {
            font-size: 13px;
            font-weight: 600;
            line-height: 156%;
        }

        .product-cena-cod {
            margin-bottom: 37px;
        }

        .products-stats {
            margin-top: 96px;
        }

        .products-stats-content {
            grid-template-columns: 1fr;
            gap: 24px;
        }

        .recommends-item {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .recommends-item img {
            width: 100%;
            height: 160px;
            object-fit: contain;
            margin-bottom: 4px;
        }

        .recommends-item p {
            font-size: 13px;
            font-weight: 700;
            line-height: 136%;
        }

        .recommends-item span {
            font-size: 16px;
            font-weight: 700;
            line-height: 136%;
            color: var(--gray);
        }

        .recommends-list.mobile {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }

        .stats-swiper .swiper-slide {
            font-size: 11px;
            font-weight: 700;
            line-height: 108%;
            padding: 10px 12px;
        }

        .stats-content-left h3,
        .stats-content-right h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 124%;
        }

        .recommends-btn-more {
            font-size: 14px;
            font-weight: 700;
            line-height: 132%;
            color: var(--white);
            border-radius: 16px;
            background: rgb(10, 151, 160);
            padding: 16px 24px;
            display: block;
            margin: 0px auto;
            text-align: center;
            width: 100%;
            margin-top: 36px;
            transition: 0.5s;
        }

        .recommends-btn-more:hover {
            background: var(--light_blue);
        }
    }
</style>